import { getApiResponseData } from "helpers";
import {
  createCategoryLocale,
  createProductLocale,
  getCategoriesByTenant,
  getCategoryLocales,
  getProduct,
  getProductLocales,
  searchProductsNames,
  updateCategoryLocale,
  updateProductLocale,
} from "services/products";
import {
  createPaymentLocale,
  createPolicyLocale,
  createShippingMethodLocale,
  getPaymentOptionById,
  getPaymentOptionLocales,
  getPaymentOptionsByTenant,
  getPoliciesByTenant,
  getPoliciesLocales,
  getShippingMethod,
  getShippingMethodLocales,
  getShippingMethodsByTenant,
  updatePaymentLocale,
  updatePolicyLocale,
  updateShippingMethodLocale,
} from "services/tenants";
import locales from "./locales";
import FormTextField from "components/Form/FormTextField/FormTextField";
import QuillTextField from "components/Form/QuillTextField/QuillTextField";
import ArraySpecificationsField from "components/Form/ArraySpecificationsField";
import QuillSnippetField from "components/Form/QuillSnippetField/QuillSnippetField";

export const translatables = {
  products: { id: "products", title: "productsTitle" },
  categories: { id: "categories", title: "categoriesTitle" },
  shipping: { id: "shipping", title: "shippingTitle" },
  payments: { id: "payments", title: "paymentsTitle" },
  policies: { id: "policies", title: "policiesTitle" },
};

export const getTranslatableItems = async (translatable, tenantId) => {
  if (translatable.id === translatables.products.id) {
    const res = await searchProductsNames(tenantId);

    return getApiResponseData(res);
  } else if (translatable.id === translatables.categories.id) {
    //we have global categories in react context + we need parent category name for custom select component
    return [];
  } else if (translatable.id === translatables.shipping.id) {
    const res = await getShippingMethodsByTenant(tenantId);
    return getApiResponseData(res);
  } else if (translatable.id === translatables.payments.id) {
    const res = await getPaymentOptionsByTenant(tenantId);
    return getApiResponseData(res).map((payment) => {
      return { id: payment.id, name: payment.label };
    });
  } else if (translatable.id === translatables.policies.id) {
    const res = await getPoliciesByTenant(tenantId);
    return getApiResponseData(res);
  }
};

export const getTranslatableItem = async (translatable, item) => {
  if (translatable.id === translatables.products.id) {
    const res = await getProduct(item.id);
    const resT = await getProductLocales(item.id);

    const orig = getApiResponseData(res);
    const trans = getApiResponseData(resT);
    return { original: orig, translations: trans };
  } else if (translatable.id === translatables.categories.id) {
    //we have global categories in react context + we need parent category name for custom select component
    const resT = await getCategoryLocales(item.id);
    const trans = getApiResponseData(resT);
    return { original: item, translations: trans };
  } else if (translatable.id === translatables.shipping.id) {
    const res = await getShippingMethod(item.id);
    const resT = await getShippingMethodLocales(item.id);
    const orig = getApiResponseData(res);
    const trans = getApiResponseData(resT);
    return { original: orig, translations: trans };
  } else if (translatable.id === translatables.payments.id) {
    const res = await getPaymentOptionById(item.id);
    const resT = await getPaymentOptionLocales(item.id);
    const orig = getApiResponseData(res);
    const trans = getApiResponseData(resT);
    return { original: orig, translations: trans };
  } else if (translatable.id === translatables.policies.id) {
    const resT = await getPoliciesLocales(item.id);
    const trans = getApiResponseData(resT);
    return { original: item, translations: trans };
  }
};

export const getLocaleLanguageName = (locale) => {
  if (locales["language-names"][locale]) {
    return locales["language-names"][locale][1];
  } else {
    return "";
  }
};

export const getTranslatableItemFields = (translatable) => {
  if (translatable.id === translatables.products.id) {
    return [
      {
        component: FormTextField,
        label: "Name",
        props: {
          name: "name",
          multiline: false,
        },
      },
      {
        component: QuillTextField,
        label: "Description",
        props: {
          name: "description",
        },
      },
      {
        component: FormTextField,
        label: "Slogan",
        props: {
          name: "slogan",
          multiline: false,
        },
      },
      {
        component: FormTextField,
        label: "Unit of Measurement",
        props: {
          name: "unitOfMeasurement",
          multiline: false,
        },
      },
      {
        component: QuillTextField,
        label: "Benefits",
        props: {
          name: "benefits",
          multiline: false,
        },
      },
      {
        component: ArraySpecificationsField,
        label: "Specifications",
        props: {
          name: "specifications",
          multiline: false,
        },
      },
    ];
  } else if (translatable.id === translatables.categories.id) {
    return [
      {
        component: FormTextField,
        label: "Name",
        props: {
          name: "name",
          multiline: false,
        },
      },
    ];
  } else if (translatable.id === translatables.shipping.id) {
    return [
      {
        component: FormTextField,
        label: "Name",
        props: {
          name: "name",
          multiline: false,
        },
      },
      {
        component: FormTextField,
        label: "Description",
        props: {
          name: "description",
          multiline: false,
        },
      },
      {
        component: FormTextField,
        label: "Info",
        props: {
          name: "info",
          multiline: false,
        },
      },
    ];
  } else if (translatable.id === translatables.payments.id) {
    return [
      {
        component: FormTextField,
        label: "Name",
        props: {
          name: "label",
          multiline: false,
        },
      },
      {
        component: FormTextField,
        label: "Description",
        props: {
          name: "description",
          multiline: false,
        },
      },
      {
        component: FormTextField,
        label: "Info",
        props: {
          name: "info",
          multiline: false,
        },
      },
    ];
  } else if (translatable.id === translatables.policies.id) {
    return [
      {
        component: FormTextField,
        label: "Name",
        props: {
          name: "name",
          multiline: false,
        },
      },
      {
        component: QuillSnippetField,
        label: "Content",
        props: {
          name: "content",
          multiline: false,
        },
      },
    ];
  }

  return [];
};

export const getTranslatableItemDefaultValues = (translatable, item) => {
  if (translatable.id === translatables.products.id) {
    return {
      name: item ? item.name : "",
      description: item ? item.description : "",
      slogan: item ? item.slogan : "",
      unitOfMeasurement: item ? item.unitOfMeasurement : "",
      benefits: item ? item.benefits : "",
      specifications: item
        ? item.specifications
          ? [...item.specifications, { name: "", content: "" }]
          : [{ name: "", content: "" }]
        : [{ name: "", content: "" }],
    };
  } else if (translatable.id === translatables.categories.id) {
    return {
      name: item ? item.name : "",
    };
  } else if (translatable.id === translatables.shipping.id) {
    return {
      name: item ? item.name : "",
      description: item ? item.description : "",
      info: item ? item.info : "",
    };
  } else if (translatable.id === translatables.payments.id) {
    return {
      label: item ? item.label : "",
      description: item ? item.description : "",
      info: item ? item.info : "",
    };
  } else if (translatable.id === translatables.policies.id) {
    return {
      name: item ? item.name : "",
      content: item ? item.content : "",
    };
  }
  return {
    name: "item.name",
  };
};

export const updateItemTranslation = async (
  translatable,
  values,
  originalItem,
  item,
  locale
) => {
  if (translatable.id === translatables.products.id) {
    const specifications = [...values.specifications];
    specifications.pop();
    if (item) {
      const res = await updateProductLocale({
        id: item.id,
        name: values.name,
        description: values.description,
        benefits: values.benefits,
        unitOfMeasurement: values.unitOfMeasurement,
        slogan: values.slogan,
        specifications: specifications,
        product: item.product,
        locale: locale,
      });

      return getApiResponseData(res);
    } else {
      const res = await createProductLocale({
        name: values.name,
        description: values.description,
        benefits: values.benefits,
        unitOfMeasurement: values.unitOfMeasurement,
        slogan: values.slogan,
        specifications: specifications,
        product: originalItem["@id"],
        locale: locale,
      });
      return getApiResponseData(res);
    }
  } else if (translatable.id === translatables.categories.id) {
    if (item) {
      const res = await updateCategoryLocale({
        id: item.id,
        category: item.category,
        locale: locale,
      });

      return getApiResponseData(res);
    } else {
      const res = await createCategoryLocale({
        name: values.name,
        category: "categories/" + originalItem.id,
        locale: locale,
      });
      return getApiResponseData(res);
    }
  } else if (translatable.id === translatables.shipping.id) {
    if (item) {
      const res = await updateShippingMethodLocale({
        id: item.id,
        name: values.name,
        description: values.description,
        info: values.info,

        shippingMethod: item.shippingMethod,
        locale: locale,
      });

      return getApiResponseData(res);
    } else {
      const res = await createShippingMethodLocale({
        name: values.name,
        description: values.description,
        info: values.info,

        shippingMethod: originalItem["@id"],
        locale: locale,
      });
      return getApiResponseData(res);
    }
  } else if (translatable.id === translatables.payments.id) {
    if (item) {
      const res = await updatePaymentLocale({
        id: item.id,
        label: values.label,
        description: values.description,
        info: values.info,

        payment: item.payment,
        locale: locale,
      });

      return getApiResponseData(res);
    } else {
      const res = await createPaymentLocale({
        label: values.label,
        description: values.description,
        info: values.info,

        payment: originalItem["@id"],
        locale: locale,
      });
      return getApiResponseData(res);
    }
  } else if (translatable.id === translatables.policies.id) {
    if (item) {
      const res = await updatePolicyLocale({
        id: item.id,
        name: values.name,
        content: values.content,
        policy: item.policy,
        locale: locale,
      });

      return getApiResponseData(res);
    } else {
      const res = await createPolicyLocale({
        name: values.name,
        content: values.content,
        policy: originalItem["@id"],
        locale: locale,
      });
      return getApiResponseData(res);
    }
  } else
    return {
      name: "item.name",
    };
};
