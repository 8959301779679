import SignIn from "pages/Home";
import SignUp from "pages/SignUp";
import withPageTitle from "components/PageTitle";
import Verify from "pages/Verify";
import Dashboard from "pages/Dashboard/Dashboard";
import InfraWizard from "pages/InfraWizard";
import CompanyInfo from "pages/CompanyInfo";
import Subscriptions from "pages/Subscriptions";
import MyProfile from "pages/MyProfile";
import PasswordReset from "pages/PasswordReset";
import Payments from "pages/Payments";
import NewCompany from "pages/NewCompany";
import Application from "pages/Application";
import Categories from "pages/Categories";
import Products from "pages/Products";
import NewProducts from "pages/Products/NewProducts";
import EditProducts from "pages/Products/EditProducts";
import Do from "pages/Do";
import Orders from "pages/Orders";
import Customers from "pages/Customers";
import Helpdesk from "pages/Helpdesk";
import Marketing from "pages/Marketing";
import Reports from "pages/Reports";
import OrderView from "pages/Orders/OrderView";
import BrandBook from "pages/BrandBook";
import Infrastructure from "pages/Infrastructure";
import Coupons from "pages/Coupons";
import CreateCoupon from "pages/Coupons/create";
import UpdateCoupon from "pages/Coupons/update";
import CopyProducts from "pages/Products/CopyProducts";
import NewOrder from "pages/Orders/NewOrder";
import ExportImport from "pages/Products/ExportImport";
import GeneralCustomOptions from "pages/GeneralCustomOptions";
import Policies from "pages/Policies";
import Integrations from "pages/Integrations";
import DnsZonePage from "pages/Infrastructure/DnsZonePage";
import Shipping from "pages/Shipping";
import NewSubscriptions from "pages/Subscriptions/NewSubscriptions";
import EditSubscriptions from "pages/Subscriptions/EditSubscriptions";
import SubsCategories from "pages/SubsCategory";
import Invoices from "pages/Invoices";
import CustomersForm from "pages/Customers/CustomersForm";
import CustomerView from "pages/Customers/CustomerView";
import NewInvoice from "pages/Invoices/NewInvoice";
import NewRecurrence from "pages/Invoices/NewRecurrence";
import EditRecurrence from "pages/Invoices/EditRecurrence";
import Cro from "pages/Marketing/Cro";
import Localization from "pages/Localization";
import CopySubscriptions from "pages/Subscriptions/CopySubscriptions";

export const routeNames = {
  home: "/",
  signUp: "/sign-up",
  signIn: "/",
  verify: "/verify",
  dashboard: "/dashboard",
  do: "/do",
  orders: "/orders",
  newOrder: "/order/create",
  orderView: "/orders/:orderId",
  customers: "/customers",
  newCustomer: "/new-customer",
  viewCustomer: "/customers/:customerId",
  helpdesk: "/helpdesk",
  infraWizard: "/infra-wizard",
  companyInfo: "/general-settings",
  brandBook: "/brand-book",
  subscriptions: "/subscriptions",
  newSubscriptions: "/new-subscription",
  editSubscriptions: "/edit-subscription/:subscriptionId",
  copySubscription: "/copy-subscription/:subscriptionId",
  memberships: "/memberships",
  billing: "/billing",
  myProfile: "/my-profile",
  passwordReset: "/password-reset",
  newCompany: "/new-company",
  app: "/app",
  infrastructure: "/infrastructure",
  dnsZone: "/infrastructure/dnsZone/:dnsZoneId",
  categories: "/categories",
  subsCategories: "/subscription-types",
  products: "/products",
  marketing: "/marketing",
  reports: "/reports",
  productsExportImport: "/products/export-import",
  newProducts: "/new-product",
  editProduct: "/edit-product/:productId",
  copyProduct: "/copy-product/:productId",
  invoices: "/invoices",
  newInvoice: "/new-invoice",
  newRecurrence: "/new-recurrence",
  editRecurrence: "/edit-recurrence/:templateId",
  editInvoice: "/edit-invoice",
  coupons: "/coupons",
  couponsEdit: "/coupons/edit/:couponId",
  couponsCreate: "/coupons/create",
  cro: "/marketing/cro",
  localization: "/localization",
  generalCustomOptions: "/general-settings/custom-options",
  policies: "/e-commerce/design/policies",
  applicationSettings: "/e-commerce/options/application-settings",
  payments: "/e-commerce/options/payments",
  shipping: "/e-commerce/options/shipping",
  integrations: "/e-commerce/options/integrations",
};

//title is the name of the localisation variable used
const routes: Array<any> = [
  {
    path: routeNames.passwordReset,
    titleVariable: "passwordResetTitle",
    component: PasswordReset,
  },
  {
    path: routeNames.dashboard,
    titleVariable: "dashboardTitle",
    component: Dashboard,
    authenticatedRoute: true,
  },
  {
    path: routeNames.do,
    titleVariable: "doTitle",
    component: Do,
    authenticatedRoute: true,
  },
  {
    path: routeNames.orders,
    titleVariable: "ordersTitle",
    component: Orders,
    authenticatedRoute: true,
  },
  {
    path: routeNames.orderView,
    titleVariable: "orderTitle",
    component: OrderView,
    authenticatedRoute: true,
  },
  {
    path: routeNames.newOrder,
    titleVariable: "newOrderTitle",
    component: NewOrder,
    authenticatedRoute: true,
  },
  {
    path: routeNames.customers,
    titleVariable: "customersTitle",
    component: Customers,
    authenticatedRoute: true,
  },
  {
    path: routeNames.newCustomer,
    titleVariable: "newCustomerTitle",
    component: CustomersForm,
    authenticatedRoute: true,
  },
  {
    path: routeNames.viewCustomer,
    titleVariable: "viewCustomerTitle",
    component: CustomerView,
    authenticatedRoute: true,
  },
  {
    path: routeNames.helpdesk,
    titleVariable: "helpdeskTitle",
    component: Helpdesk,
    authenticatedRoute: true,
  },
  {
    path: routeNames.marketing,
    titleVariable: "marketingTitle",
    component: Marketing,
    authenticatedRoute: true,
  },
  {
    path: routeNames.reports,
    titleVariable: "reportsTitle",
    component: Reports,
    authenticatedRoute: true,
  },
  {
    path: routeNames.infraWizard,
    titleVariable: "infraWizardTitle",
    component: InfraWizard,
    authenticatedRoute: true,
  },
  {
    path: routeNames.newCompany,
    titleVariable: "infraWizardTitle",
    component: NewCompany,
    authenticatedRoute: true,
  },
  {
    path: routeNames.products,
    titleVariable: "productsTitle",
    component: Products,
    authenticatedRoute: true,
  },
  {
    path: routeNames.newProducts,
    titleVariable: "newProductTitle",
    component: NewProducts,
    authenticatedRoute: true,
  },
  {
    path: routeNames.editProduct,
    titleVariable: "editProductTitle",
    component: EditProducts,
    authenticatedRoute: true,
  },
  {
    path: routeNames.copyProduct,
    titleVariable: "newProductTitle",
    component: CopyProducts,
    authenticatedRoute: true,
  },
  {
    path: routeNames.productsExportImport,
    titleVariable: "productsExportImportTitle",
    component: ExportImport,
    authenticatedRoute: true,
  },
  {
    path: routeNames.companyInfo,
    titleVariable: "companyInfoTitle",
    component: CompanyInfo,
    authenticatedRoute: true,
    settingsRoute: true,
  },
  {
    path: routeNames.brandBook,
    titleVariable: "brandBookHeader",
    component: BrandBook,
    authenticatedRoute: true,
    settingsRoute: true,
  },
  {
    path: routeNames.infrastructure,
    titleVariable: "infrastructureHeader",
    component: Infrastructure,
    authenticatedRoute: true,
    settingsRoute: true,
  },

  {
    path: routeNames.dnsZone,
    titleVariable: "dnsZoneTitle",
    component: DnsZonePage,
    authenticatedRoute: true,
    settingsRoute: true,
  },
  {
    path: routeNames.generalCustomOptions,
    titleVariable: "customOptions",
    component: GeneralCustomOptions,
    authenticatedRoute: true,
    settingsRoute: true,
  },
  {
    path: routeNames.categories,
    titleVariable: "categoriesTitle",
    component: Categories,
    authenticatedRoute: true,
  },
  {
    path: routeNames.subsCategories,
    titleVariable: "subsCategoriesTitle",
    component: SubsCategories,
    authenticatedRoute: true,
  },
  {
    path: routeNames.app,
    titleVariable: "applicationTitle",
    component: Application,
    authenticatedRoute: true,
  },
  {
    path: routeNames.subscriptions,
    titleVariable: "subscriptionsTitle",
    component: Subscriptions,
    authenticatedRoute: true,
  },
  {
    path: routeNames.newSubscriptions,
    titleVariable: "newSubscriptionTitle",
    component: NewSubscriptions,
    authenticatedRoute: true,
  },
  {
    path: routeNames.copySubscription,
    titleVariable: "newSubscriptionTitle",
    component: CopySubscriptions,
    authenticatedRoute: true,
  },
  {
    path: routeNames.editSubscriptions,
    titleVariable: "editSubscriptionTitle",
    component: EditSubscriptions,
    authenticatedRoute: true,
  },
  {
    path: routeNames.myProfile,
    titleVariable: "myProfileTitle",
    component: MyProfile,
    authenticatedRoute: true,
  },
  {
    path: routeNames.coupons,
    titleVariable: "couponsTitle",
    component: Coupons,
    authenticatedRoute: true,
  },
  {
    path: routeNames.couponsCreate,
    titleVariable: "createCouponsTitle",
    component: CreateCoupon,
    authenticatedRoute: true,
  },
  {
    path: routeNames.couponsEdit,
    titleVariable: "editCouponTitle",
    component: UpdateCoupon,
    authenticatedRoute: true,
  },
  {
    path: routeNames.cro,
    titleVariable: "croTitle",
    component: Cro,
    authenticatedRoute: true,
  },
  {
    path: routeNames.localization,
    titleVariable: "localizationTitle",
    component: Localization,
    authenticatedRoute: true,
  },
  {
    path: routeNames.invoices,
    titleVariable: "invoicesTitle",
    component: Invoices,
    authenticatedRoute: true,
  },
  {
    path: routeNames.newRecurrence,
    titleVariable: "newRecurrenceTitle",
    component: NewRecurrence,
    authenticatedRoute: true,
  },
  {
    path: routeNames.editRecurrence,
    titleVariable: "editRecurrenceTitle",
    component: EditRecurrence,
    authenticatedRoute: true,
  },
  {
    path: routeNames.newInvoice,
    titleVariable: "newInvoiceTitle",
    component: NewInvoice,
    authenticatedRoute: true,
  },
  {
    path: routeNames.policies,
    titleVariable: "policiesTitle",
    component: Policies,
    authenticatedRoute: true,
    ecommerceDesignMenu: true,
  },
  {
    path: routeNames.applicationSettings,
    titleVariable: "applicationTitle",
    component: Application,
    authenticatedRoute: true,
    ecommerceCustomOptionsMenu: true,
  },
  {
    path: routeNames.payments,
    titleVariable: "paymentsTitle",
    component: Payments,
    authenticatedRoute: true,
    ecommerceCustomOptionsMenu: true,
  },
  {
    path: routeNames.shipping,
    titleVariable: "shippingTitle",
    component: Shipping,
    authenticatedRoute: true,
    ecommerceCustomOptionsMenu: true,
  },
  {
    path: routeNames.integrations,
    titleVariable: "integrationsTitle",
    component: Integrations,
    authenticatedRoute: true,
    ecommerceCustomOptionsMenu: true,
  },
];

//These don't have the layout with navbar & footer
const sRoutes: Array<any> = [
  {
    path: routeNames.home,
    titleVariable: "homeTitle",
    component: SignIn,
  },
  {
    path: routeNames.signUp,
    titleVariable: "signUpTitle",
    component: SignUp,
  },
  {
    path: routeNames.verify,
    titleVariable: "verifyTitle",
    component: Verify,
  },
];

const mappedRoutes = routes.map((route) => {
  const requiresAuth = Boolean(route.authenticatedRoute);
  const settingRoute = Boolean(route.settingsRoute);
  const ecommerceDesignMenu = Boolean(route.ecommerceDesignMenu);
  const ecommerceCustomOptionsMenu = Boolean(route.ecommerceCustomOptionsMenu);
  const wrappedComponent = withPageTitle(route.titleVariable, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth,
    settingsRoute: settingRoute,
    ecommerceDesignMenu: ecommerceDesignMenu,
    ecommerceCustomOptionsMenu: ecommerceCustomOptionsMenu,
  };
});

//These don't have the layout with navbar & footer
export const specialRoutes = sRoutes.map((route) => {
  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(route.titleVariable, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth,
  };
});

export default mappedRoutes;
