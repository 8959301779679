import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import {
  createSettings,
  createTax,
  getSettingsByTenant,
  getTaxesByTenant,
  updateSettings,
} from "services/tenants";

import PapperCollapse from "components/PaperCollapse";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { Box, Stack } from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { ISettings, ITax } from "interfaces";
import SwitchField from "components/Form/SwitchField/SwitchField";
import ArrayNumberPercentageField from "components/Form/ArrayNumberPercentageField";
import { LoadingButton } from "@mui/lab";
import { errorMessage } from "helpers";
import { useTenantContext } from "components/contexts/TenantContext";
import MarketingMenu from "components/Menu/MarketingMenu";
import FormTextField from "components/Form/FormTextField/FormTextField";

const Cro = () => {
  const { selectedTenant, tenants, updateTrigger, setUpdateTrigger } =
    useUserContext();
  const { taxes, taxesLoading, setUpdateTaxesTrigger, updateTaxesTrigger } =
    useTenantContext();
  const { setLoading } = useLoadingContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const {
    settings,
    updateSettingsTrigger,
    setUpdateSettingsTrigger,
    settingsLoading,
    setSettingsLoading,
  } = useTenantContext();
  const [isEditingTax, setIsEditingTax] = useState(false);
  const translation = useTranslation();

  const submitForm = async (values: any, actions: any) => {
    try {
      await updateSettings({
        ...settings,
        id: settings.id,
        freeShippingMessageEnabled: values.freeShippingMessageEnabled,
        freeShippingMessage: values.freeShippingMessage,
      });
    } catch (e) {
      actions.setSubmitting(false);
      setErrorMessage(errorMessage(e));
      setUpdateTrigger(!updateTrigger);
      setUpdateSettingsTrigger(!updateSettingsTrigger);
      return;
    }
    actions.setSubmitting(false);
    setSuccessMessage(translation.savedMessage);
  };

  return (
    <>
      <MarketingMenu />
      <PapperCollapse title={translation.croTitle}>
        <Formik
          initialValues={{
            freeShippingMessageEnabled: settings.freeShippingMessageEnabled
              ? settings.freeShippingMessageEnabled
              : false,
            freeShippingMessage: settings.freeShippingMessage
              ? settings.freeShippingMessage
              : "",
          }}
          enableReinitialize
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            submitForm(values, actions);
          }}
        >
          {({ isSubmitting, submitForm, values, resetForm }) => (
            <Box sx={{ height: "100%" }}>
              <Stack spacing={2} sx={{ width: 600 }}>
                <SwitchField
                  name="freeShippingMessageEnabled"
                  label={translation.cro.freeShippingMessageEnabledLabel}
                ></SwitchField>

                <FormTextField
                  multiline
                  name="freeShippingMessage"
                  label={translation.cro.freeShippingMessageLabel}
                />

                <Box display={"flex"} justifyContent="flex-start">
                  <LoadingButton
                    type="submit"
                    onClick={submitForm}
                    variant="contained"
                    loading={isSubmitting}
                    disabled={isEditingTax}
                  >
                    {translation.save}
                  </LoadingButton>
                </Box>
              </Stack>
            </Box>
          )}
        </Formik>
      </PapperCollapse>
    </>
  );
};

export default Cro;
