import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import * as Yup from "yup";
import useTranslation from "components/customHooks/translations";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import { Formik } from "formik";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { formDrawerWidth } from "components/Form/constants";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { IShippingMethod, IDropTag, ICourier, ITax } from "interfaces";
import { errorMessage } from "helpers";
import dayjs from "dayjs";
import DatePickerField from "components/Form/DatePickerField";
import CheckboxField from "components/Form/CheckboxField/CheckboxField";
import CheckboxAudioField from "components/Form/CheckboxAudioField/CheckboxAudioField";
import { LoadingButton } from "@mui/lab";
import AutocompleteTagsField from "components/Form/AutocompleteTagsField";
import {
  createShippingMethod,
  deleteShippingMethod,
  getCouriersByTenant,
  getTaxesByTenant,
  updateShippingMethod,
} from "services/tenants";
import FormNumberField from "components/Form/FormNumberField";
import { useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import InfoPopover from "components/InfoPopover";
import SwitchField from "components/Form/SwitchField/SwitchField";
import { useTenantContext } from "components/contexts/TenantContext";

const ShippingMethodForm = ({
  showNewShippingMethod,
  setShowNewShippingMethod,
  shippingMethodToEdit,
  setShippingMethodToEdit,
  updateTrigger,
  setUpdateTrigger,
  setNewShippingMethod,
}: {
  showNewShippingMethod: boolean;
  setShowNewShippingMethod: React.Dispatch<React.SetStateAction<boolean>>;
  shippingMethodToEdit?: IShippingMethod | null;
  setShippingMethodToEdit?: React.Dispatch<
    React.SetStateAction<IShippingMethod | null>
  >;
  updateTrigger: boolean;
  setUpdateTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setNewShippingMethod?: any;
}) => {
  const { userId, selectedTenant, tenants } = useUserContext();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const [openDelete, setOpenDelete] = useState(false);
  const [services, setServices] = useState<any>([]);
  const [taxesLoading, setTaxesLoading] = useState(true);
  const [taxes, setTaxes] = useState<ITax[]>([]);
  const [pickupPoints, setPickupPoints] = useState<any>([]);
  const [couriers, setCouriers] = useState<ICourier[]>([]);

  const translation = useTranslation();
  const navigate = useNavigate();
  const { updateSettingsTrigger, setUpdateSettingsTrigger } =
    useTenantContext();

  const shippingMethodSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
  });

  const handleDeleteShippingMethod = () => {
    if (shippingMethodToEdit) {
      setDeleteLoading(true);
      deleteShippingMethod(shippingMethodToEdit)
        .then(() => {
          setSuccessMessage(translation.deletedMessage);
          setOpenDelete(false);
          setShowNewShippingMethod(false);
          setUpdateTrigger(!updateTrigger);
        })
        .catch((e) => setErrorMessage(errorMessage(e)))
        .finally(() => {
          setDeleteLoading(false);
        });
    }
  };

  useEffect(() => {
    setTaxesLoading(true);
    if (tenants !== null) {
      getTaxesByTenant(tenants[selectedTenant].id)
        .then((res) => {
          setTaxes(res.data["hydra:member"]);
          setTaxesLoading(false);
        })
        .catch((e) => console.log(e));
      getCouriersByTenant(tenants[selectedTenant].id)
        .then((res) => {
          const newCouriers = res.data["hydra:member"].filter(
            (courier: ICourier) => {
              return courier.settings.length === undefined;
            }
          );

          setCouriers(newCouriers);
        })
        .catch((e) => console.log(e));
    }
  }, [selectedTenant, tenants, updateTrigger]);

  return (
    <>
      <Drawer
        anchor="right"
        open={showNewShippingMethod}
        onClose={() => {
          setShowNewShippingMethod(false);
          if (setShippingMethodToEdit) {
            setShippingMethodToEdit(null);
          }
        }}
        sx={{
          "& .MuiDrawer-paper": { width: formDrawerWidth },
        }}
      >
        <Stack justifyContent={"space-between"} sx={{ p: 4, height: "100%" }}>
          <Box>
            <Formik
              initialValues={{
                name: shippingMethodToEdit ? shippingMethodToEdit.name : "",
                description: shippingMethodToEdit
                  ? shippingMethodToEdit.description
                  : "",
                info: shippingMethodToEdit ? shippingMethodToEdit.info : "",
                price: shippingMethodToEdit
                  ? tenants[selectedTenant].settings.taxIncluded
                    ? shippingMethodToEdit.netPrice
                    : shippingMethodToEdit.grossPrice
                  : "",
                tax: shippingMethodToEdit
                  ? shippingMethodToEdit.tax
                    ? (shippingMethodToEdit.tax as unknown as any)["@id"]
                    : taxes.length === 1
                    ? taxes[0]["@id"]
                    : ""
                  : taxes.length === 1
                  ? taxes[0]["@id"]
                  : "",
                priceCurrency: shippingMethodToEdit
                  ? shippingMethodToEdit.priceCurrency
                  : tenants
                  ? tenants[selectedTenant].settings.defaultCurrency
                  : "",
                type: shippingMethodToEdit
                  ? shippingMethodToEdit.type
                  : "simple",
                freeTreshold: shippingMethodToEdit
                  ? shippingMethodToEdit.freeTreshold
                  : "",
                discountsInFreeTreshold: shippingMethodToEdit
                  ? shippingMethodToEdit.discountsInFreeTreshold
                  : false,
                courier: shippingMethodToEdit
                  ? shippingMethodToEdit.courier
                    ? shippingMethodToEdit.courier
                    : ""
                  : "",
              }}
              validationSchema={shippingMethodSchema}
              enableReinitialize
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);
                const netPrice = Number(values.price);
                const grossPrice = Number(values.price);
                const freeTreshold = Number(values.freeTreshold);

                if (shippingMethodToEdit) {
                  updateShippingMethod({
                    ...shippingMethodToEdit,
                    name: values.name,
                    description: values.description,
                    info: values.info,
                    type: values.type,
                    netPrice: netPrice,
                    grossPrice: grossPrice,
                    priceCurrency:
                      tenants[selectedTenant].settings.defaultCurrency,
                    tax: values.tax,
                    courier: values.courier !== "" ? values.courier : undefined,
                    freeTreshold: freeTreshold,
                    discountsInFreeTreshold: values.discountsInFreeTreshold,
                  })
                    .then(() => {
                      setSubmitting(false);
                      setShowNewShippingMethod(false);
                      if (setShippingMethodToEdit) {
                        setShippingMethodToEdit(null);
                      }
                      setSuccessMessage(translation.savedMessage);
                      setUpdateTrigger(!updateTrigger);
                      setUpdateSettingsTrigger(!updateSettingsTrigger);
                    })
                    .catch((e) => {
                      setErrorMessage(errorMessage(e));
                      setSubmitting(false);
                    });
                } else {
                  createShippingMethod({
                    name: values.name,
                    description: values.description,
                    info: values.info,
                    type: values.type,
                    netPrice: netPrice,
                    grossPrice: grossPrice,
                    priceCurrency:
                      tenants[selectedTenant].settings.defaultCurrency,
                    tax: values.tax,
                    courier: values.courier !== "" ? values.courier : undefined,
                    freeTreshold: freeTreshold,
                    discountsInFreeTreshold: values.discountsInFreeTreshold,
                    tenant: "/tenants/" + tenants[selectedTenant].id,
                    active: true,
                  })
                    .then((res: any) => {
                      setSubmitting(false);
                      setShowNewShippingMethod(false);

                      setSuccessMessage(translation.savedMessage);
                      setUpdateTrigger(!updateTrigger);
                      if (setNewShippingMethod) {
                        setNewShippingMethod(res.data["@id"]);
                      }
                    })
                    .catch((e: any) => {
                      setErrorMessage(errorMessage(e));
                      setSubmitting(false);
                    });
                }
              }}
            >
              {({ handleSubmit, isSubmitting, values }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Stack spacing={2}>
                    <Typography variant="h5">
                      {translation.shipping.shippingMethodFormTitle}
                    </Typography>
                    <Stack spacing={4}>
                      <FormTextField
                        label={translation.shipping.nameLabel}
                        name="name"
                      />
                      <FormTextField
                        label={translation.shipping.infoLabel}
                        multiline
                        name="info"
                      />
                      <FormTextField
                        label={translation.shipping.descriptionLabel}
                        multiline
                        name="description"
                      />
                      {shippingMethodToEdit == null && (
                        <SelectField
                          label={translation.shipping.typeLabel}
                          name="type"
                        >
                          <MenuItem value="">None</MenuItem>
                          <MenuItem value="courier">
                            {translation.shipping.typeCourier}
                          </MenuItem>
                          <MenuItem value="simple">
                            {translation.shipping.typeSimple}
                          </MenuItem>
                        </SelectField>
                      )}
                      {values.type === "courier" && (
                        <>
                          <SelectField
                            label={translation.shipping.courierLabel}
                            name="courier"
                          >
                            <MenuItem value="">None</MenuItem>
                            {couriers.map((courier) => (
                              <MenuItem key={courier.id} value={courier["@id"]}>
                                {courier.name}
                              </MenuItem>
                            ))}
                          </SelectField>
                          {couriers.length === 0 && (
                            <Typography color={"warning"}></Typography>
                          )}
                        </>
                      )}
                      <Stack
                        direction={"row"}
                        spacing={2}
                        alignItems={"flex-end"}
                      >
                        <FormNumberField
                          type="float"
                          label={translation.shipping.priceLabel}
                          name="price"
                        />
                        <Typography>
                          {tenants[selectedTenant].settings.defaultCurrency}
                        </Typography>
                      </Stack>
                      {taxesLoading ? (
                        <CircularProgress></CircularProgress>
                      ) : taxes.length > 0 ? (
                        <SelectField
                          name="tax"
                          label={translation.products.taxPercentageField}
                        >
                          {taxes.map((tax) => {
                            return (
                              <MenuItem
                                value={"/taxes/" + tax.id}
                                key={"productTax" + tax.id}
                              >
                                {tax.taxPercentage + "%"}
                              </MenuItem>
                            );
                          })}
                        </SelectField>
                      ) : (
                        <Button
                          variant="outlined"
                          color={"error"}
                          onClick={() => {
                            navigate(routeNames.generalCustomOptions);
                          }}
                        >
                          {translation.products.setTaxes}
                        </Button>
                      )}
                      <Stack direction={"row"} spacing="2">
                        <FormNumberField
                          type="float"
                          label={translation.shipping.freeTresholdLabel}
                          name="freeTreshold"
                        />
                        <InfoPopover
                          infoContent={
                            translation.shipping.freeTresholdDescription
                          }
                        />
                      </Stack>
                      <Stack direction={"row"} spacing="2">
                        <SwitchField
                          label={translation.shipping.discountsInTresholdLabel}
                          name="discountsInFreeTreshold"
                        />
                        <InfoPopover
                          infoContent={
                            translation.shipping.discountsInTresholdDescription
                          }
                        />
                      </Stack>
                      <Stack
                        direction={"row"}
                        spacing={2}
                        justifyContent={"space-between"}
                      >
                        <Button
                          variant={"outlined"}
                          onClick={() => {
                            setShowNewShippingMethod(false);
                            if (setShippingMethodToEdit) {
                              setShippingMethodToEdit(null);
                            }
                          }}
                        >
                          {translation.closeButton}
                        </Button>
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {translation.saveButton}
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </form>
              )}
            </Formik>
          </Box>
          {shippingMethodToEdit && (
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"flex-end"}
            >
              <Button
                variant="outlined"
                color="error"
                onClick={() => setOpenDelete(true)}
              >
                {translation.deleteButton}
              </Button>
            </Box>
          )}
        </Stack>
      </Drawer>
      <Dialog
        open={openDelete}
        onClose={() => {
          setOpenDelete(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translation.shipping.shippingMethodDeleteConfirmMessage}
        </DialogTitle>
        <DialogContent>
          <Typography>{shippingMethodToEdit?.name}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            onClick={() => {
              setOpenDelete(false);
            }}
          >
            {translation.closeButton}
          </Button>

          <LoadingButton
            onClick={() => handleDeleteShippingMethod()}
            variant="contained"
            loading={deleteLoading}
          >
            {translation.deleteButton}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShippingMethodForm;
