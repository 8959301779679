import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { Formik, FormikHelpers } from "formik";
import {
  createSettings,
  getSettingsByTenant,
  updateSettings,
} from "services/tenants";

import PapperCollapse from "components/PaperCollapse";
import { useLoadingContext } from "components/contexts/LoadingContext";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { ISettings } from "interfaces";
import { LoadingButton } from "@mui/lab";
import { errorMessage } from "helpers";
import locales from "helpers/locales";
import FormTextField from "components/Form/FormTextField/FormTextField";
import InvoiceSeries from "./InvoiceSeries";
import Taxes from "pages/Taxes";
import { useTenantContext } from "components/contexts/TenantContext";

interface LocalizationFormValues {
  storeLocale?: string;
  enabledLocales: string[];
}

const LocalizationSettings = () => {
  const { updateTrigger, setUpdateTrigger } = useUserContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const {
    settings,
    updateSettingsTrigger,
    setUpdateSettingsTrigger,
    settingsLoading,
    setSettingsLoading,
  } = useTenantContext();
  const [enabledLocales, setEnabledLocales] = useState<string[]>([]);
  const translation = useTranslation();

  useEffect(() => {
    if (settings !== null) {
      console.log(settings);
      if (settings.enabledLocales && settings.enabledLocales.length > 0) {
        setEnabledLocales(settings.enabledLocales);
      } else {
        setEnabledLocales(Object.values(locales["primary-dialects"]));
      }
    }
  }, [settings]);

  const submitFormLocalization = async (
    values: LocalizationFormValues,
    actions: FormikHelpers<LocalizationFormValues>
  ) => {
    if (!values.storeLocale) {
      setErrorMessage(translation.localization.selectDefaultLanguage);
      actions.setSubmitting(false);
      return;
    }
    try {
      if (settings) {
        setSettingsLoading(true);
        await updateSettings({
          ...settings,
          storeLocale: values.storeLocale,
          enabledLocales: values.enabledLocales,
        });
        actions.setSubmitting(false);
        setUpdateTrigger(!updateTrigger);
        setUpdateSettingsTrigger(!updateSettingsTrigger);
        setSuccessMessage(translation.currency.currencySaved);
      }
    } catch (e) {
      actions.setSubmitting(false);
      setErrorMessage(errorMessage(e));
      return;
    }
  };

  const initialValuesLocalisation: LocalizationFormValues = {
    storeLocale: settings
      ? settings.storeLocale
        ? settings.storeLocale
        : ""
      : "",
    enabledLocales: settings
      ? settings.enabledLocales
        ? settings.enabledLocales
        : []
      : [],
  };

  return (
    <Stack spacing={4}>
      {settings == null ? (
        <>
          {!settingsLoading && (
            <Typography>{translation.waitForSettings}</Typography>
          )}
        </>
      ) : (
        <>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h5">
              {translation.localizationSettingsTitle}
            </Typography>
            <Formik
              initialValues={initialValuesLocalisation}
              enableReinitialize
              onSubmit={(values, actions) => {
                actions.setSubmitting(true);
                submitFormLocalization(values, actions);
              }}
            >
              {({
                isSubmitting,
                submitForm,
                values,
                errors,
                touched,
                setFieldValue,
                setFieldTouched,
              }) => (
                <Box sx={{ py: 2, height: "100%" }}>
                  <Stack spacing={2}>
                    <Box>
                      <FormControl variant="standard" sx={{ minWidth: 220 }}>
                        <InputLabel id={"default-locale-select-standard-label"}>
                          {translation.localization.storeLocaleLabel}
                        </InputLabel>
                        <Select
                          onChange={(event) => {
                            const locale = event.target.value;
                            if (!values.enabledLocales.includes(locale)) {
                              const newEnabledLocales = [
                                ...values.enabledLocales,
                              ];
                              newEnabledLocales.push(locale);
                              setFieldValue(
                                "enabledLocales",
                                newEnabledLocales
                              );
                              setEnabledLocales(newEnabledLocales);
                            }
                            setFieldValue("storeLocale", locale);
                          }}
                          name="storeLocale"
                          labelId={"default-locale-select-standard-label"}
                          id={"default-locale-select-standard"}
                          label={translation.localization.storeLocaleLabel}
                          value={values.storeLocale}
                          error={touched["storeLocale"] && !!errors.storeLocale}
                          MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                        >
                          {enabledLocales.map((locale) => {
                            return (
                              <MenuItem
                                value={locale}
                                key={"default-" + locale}
                              >
                                {
                                  locales["language-names"][
                                    locale as keyof (typeof locales)["language-names"]
                                  ][1]
                                }
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{ maxWidth: 400 }}>
                      <Autocomplete
                        id={"enabledLocales"}
                        multiple
                        onChange={(
                          _event: React.SyntheticEvent<Element, Event>,
                          selected: string[]
                        ) => {
                          setFieldValue("enabledLocales", selected);
                          if (selected.length > 0) {
                            setEnabledLocales(selected);
                          } else {
                            setEnabledLocales(
                              Object.values(locales["primary-dialects"])
                            );
                          }
                          if (values.storeLocale)
                            if (!selected.includes(values.storeLocale))
                              setFieldValue("storeLocale", "");
                        }}
                        onBlur={() => setFieldTouched("enabledLocales", true)}
                        defaultValue={
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          values.enabledLocales as any
                        }
                        value={values.enabledLocales}
                        options={Object.values(locales["primary-dialects"])}
                        getOptionLabel={(option) =>
                          locales["language-names"][
                            option as keyof (typeof locales)["language-names"]
                          ][1]
                        }
                        renderTags={(value: readonly string[], getTagProps) =>
                          value.map((option: string, index: number) => (
                            // eslint-disable-next-line react/jsx-key
                            <Chip
                              variant="outlined"
                              label={
                                locales["language-names"][
                                  option as keyof (typeof locales)["language-names"]
                                ][1]
                              }
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            error={
                              touched.enabledLocales && !!errors.enabledLocales
                            }
                            helperText={
                              touched.enabledLocales && !!errors.enabledLocales
                                ? errors.enabledLocales
                                : ""
                            }
                            variant="standard"
                            {...params}
                            label={translation.localization.enabledLocales}
                          />
                        )}
                      />
                    </Box>
                    <Box display={"flex"} justifyContent="flex-start">
                      <LoadingButton
                        type="submit"
                        onClick={submitForm}
                        variant="contained"
                        loading={isSubmitting}
                      >
                        {translation.save}
                      </LoadingButton>
                    </Box>
                  </Stack>
                </Box>
              )}
            </Formik>
          </Paper>
        </>
      )}
    </Stack>
  );
};

export default LocalizationSettings;
